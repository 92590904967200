<template>
  <div>
    <alert-banner
      v-if="bannerIndex < banners.length"
      :key="bannerIndex"
      :banner="banners[bannerIndex]"
      @input="nextIndex"
    />
  </div>
</template>

<script>
import AlertBanner from "./AlertBanner.vue";
export default {
  components: { AlertBanner },
  data: () => ({
    bannerIndex: 0,
  }),
  methods: {
    nextIndex() {
      this.bannerIndex++;
    },
  },
  watch: {
    banners() {
      this.bannerIndex = 0;
    },
  },
  props: {
    banners: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style></style>
