<template>
  <div>
    <cover-editor @refresh="refresh" />
    <photo-editor @refresh="refresh" style="margin-top: -50px" />
  </div>
</template>

<script>
import CoverEditor from "./CoverEditor.vue";
import PhotoEditor from "./PhotoEditor.vue";
export default {
  data: () => ({
    fileLoading: false,
    fileError: false,
    photoLoading: false,
    photoError: false,
  }),
  methods: {
    refresh() {
      this.$emit("refresh");
    },
  },
  components: { CoverEditor, PhotoEditor },
};
</script>

<style></style>
