<template>
  <div id="#form-sell-party">
    <v-progress-linear class="mb-4" indeterminate v-if="loading" />
    <v-row class="px-1" v-if="parties.length">
      <v-col cols="12" sm="6" md="4" v-for="party in parties" :key="party.id">
        <v-card outlined elevation="0" class="rounded-lg">
          <v-img
            @click="selectParty(party)"
            :src="party.cover"
            class="rounded-lg rounded-b-0"
            :aspect-ratio="16 / 9"
            style="cursor: pointer"
          />
          <v-card-title class="d-block">
            <p class="text-overline lh-1 mb-2">
              {{ party.date | date("DD [de] MMMM - HH:mm") }}
            </p>
            <h6 class="ma-0 lh-1" style="word-break: normal">
              {{ party.name }}
            </h6>
          </v-card-title>
          <v-card-actions>
            <v-btn color="primary" block @click="selectParty(party)">
              Selecionar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <template v-else-if="!loading">
      <div class="text-center">
        <v-avatar color="grey lighten-4" size="50">
          <v-icon x-large class="display-1 grey darken-1"> mdi-ticket </v-icon>
        </v-avatar>
        <p class="text--disabled mt-2">Não há eventos disponíveis para venda</p>
      </div>
    </template>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import ORGANIZATION from "@/services/admin/organization";
export default {
  emit: ["input"],
  data: () => ({
    parties: [],
    loading: false,
  }),
  methods: {
    selectParty(party) {
      this.$emit("input", party);
    },
    async getParties() {
      try {
        this.loading = true;
        this.sParty = null;
        this.parties = [];

        var response = await ORGANIZATION.party.getAll(
          this.selectedOrganization.id
        );
        this.parties = response.parties.filter(
          (p) => p.active && moment(p.endDate || p.date).isAfter(moment())
        );

        if (!!this.$route.query?.id) {
          var party = this.parties.find((p) => p.id == this.$route.query.id);
          if (!!party) this.selectParty(party);
          this.$router.replace({ query: {} });
        } else if (this.parties.length == 1) this.selectParty(this.parties[0]);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  watch: {
    "selectedOrganization.id"() {
      this.getParties();
    },
  },
  props: {
    value: {
      type: Object | null,
      default: () => null,
    },
  },
  mounted() {
    this.getParties();
  },
};
</script>

<style></style>
