<template>
  <div id="form-sell-ticket">
    <div class="d-flex justify-center">
      <v-card
        v-if="tableMap"
        class="flex-grow-1 rounded-lg"
        style="max-width: 600px; width: 100px"
        outlined
        elevation="0"
      >
        <table-selector
          class="w-full elevation-0"
          :party="party"
          :tableMap="tableMap"
          v-model="selectedTable"
          colorMode="available"
        />
      </v-card>
    </div>
    <div class="d-flex justify-space-between mt-2">
      <v-btn class="mt-2" icon @click="$emit('prev')" small>
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn color="primary" :disabled="!selectedTable" @click="confirmSelect">
        Continuar
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TableSelector from "@/components/global/partyTable/TableSelector.vue";
export default {
  components: { TableSelector },
  emit: ["input"],
  data: () => ({
    tableMap: null,
    selectedTable: null,
  }),
  methods: {
    confirmSelect() {
      this.$emit("input", this.selectedTable);
    },
    formattedTable() {
      // TODO - adicionar para apenas ingressos com mesa

      this.tableMap = null;
      if (!this.cart) return;

      const tempGroup = this.ticketBlock.ticketGroup.TableGroup;
      if (!tempGroup) return;
      var { Map, ...Group } = tempGroup;
      const data = {
        ...Map,
        Groups: [{ ...Group }],
      };
      this.tableMap = data;
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  watch: {
    user: {
      handler() {
        this.$nextTick(() => {
          this.formattedTable();
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.$root.$on("sell-reset", () => {
      this.selectedTable = null;
    });
  },
  props: {
    value: {
      type: String | null,
      default: () => null,
    },
    party: {
      type: Object,
      default: () => null,
    },
    cart: {
      type: Object,
      default: () => null,
    },
    user: {
      type: Object,
      default: () => null,
    },
  },
};
</script>

<style></style>
