<template>
  <v-data-table
    dense
    :headers="headers"
    :items="sales"
    sort-by="createdAt"
  >
    <template v-slot:item.createdAt="{ item }">
      {{ item.date | date }}
    </template>
    <template v-slot:item.amount="{ item }">
      {{ item.amount | currency(true) }}
    </template>
    <template v-slot:item.receivedBy="{ item }">
      {{ item.by.name }}
    </template>
  </v-data-table>
</template>

<script>
export default {
  computed: {
    headers() {
      return [
        {
          text: "Data",
          value: "createdAt",
          sortable: this.$vuetify.breakpoint.smAndUp,
          align: "start",
        },
        {
          text: "Valor",
          value: "amount",
          sortable: this.$vuetify.breakpoint.smAndUp,
        },
        {
          text: "Recebido por",
          value: "receivedBy",
          sortable: this.$vuetify.breakpoint.smAndUp,
        },
      ];
    },
  },
  props: {
    sales: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style></style>
