<template>
  <v-card elevation="0" class="rounded-lg">
    <v-img :src="data.nextParty.cover" :aspect-ratio="16 / 9"></v-img>
    <v-card-text>
      <div class="d-flex justify-space-between">
        <h6>
          {{ data.nextParty.name }}
        </h6>
        {{ diference }}
      </div>
      <div class="d-flex justify-space-between">
        <span>
          {{ data.mySales._count }} venda{{
            data.mySales._count > 1 ? "s" : ""
          }}
        </span>
      </div>
      <v-btn
        :to="{ name: 'admin.sell' }"
        block
        color="success"
        class="mt-2"
        v-if="hasPermission(32)"
      >
        Vender
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
moment.locale("pt");

import { mapGetters } from "vuex";

export default {
  data: () => ({
    diference: null,
  }),
  mounted() {
    this.diference = moment(this.data.nextParty.date).fromNow();
  },
  computed: {
    ...mapGetters("auth", ["hasPermission"]),
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
