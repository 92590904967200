<template>
  <div>
    <error-message />
  </div>
  
</template>

<script>
import ErrorMessage from '../../components/global/ErrorMessage.vue'
export default {
  components: { ErrorMessage },

}
</script>

<style>

</style>