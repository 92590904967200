<template>
  <div class="d-flex flex-column align-center justify-center h-full">
    <v-icon size="100" color="grey lighten-1" class="mb-2">
      {{ v.icon }}
    </v-icon>
    <h2>{{ v.message }}</h2>
    <p class="text-subtitle-1 mb-6">{{ v.description }}</p>

    <slot />

    <v-btn
      v-for="(btn, i) in v.buttons"
      :key="i"
      @click="btn.functionCallback(btn)"
      :color="btn.color || 'primary'"
      large
      class="mb-2"
    >
      {{ btn.text }}
    </v-btn>
  </div>
</template>

<script>
export default {
  data: () => ({
    errorTypes: {
      404: {
        icon: "mdi-alert-circle-outline",
        message: "Página não encontrada",
        description: "A página que você está tentando acessar não existe.",
        buttons: [
          {
            action: "goBack",
            text: "Voltar para a página anterior",
          },
          {
            action: "goToHome",
            to: "/app",
            text: "Voltar para o início",
          },
        ],
      },
      offline: {
        icon: "mdi-web-off",
        message: "Sem conexão",
        description: "Você está sem conexão com a internet.",
        buttons: [
          {
            action: "goTo",
            to: "/app",
            text: "Voltar para o início",
          },
        ],
      },
    },
  }),

  methods: {
    goToHome() {
      const isAdmin = this.$route.name.includes("admin");
      this.$router.push(isAdmin ? "/admin" : "/app");
    },
    goTo(btn) {
      this.$router.push(btn.to);
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  computed: {
    v() {
      var data = Object.assign({}, this.errorTypes[this.type], this.options);
      data.buttons = data.buttons
        .filter((btn) => {
          if (btn.action != "goBack") return true;
          if (!window.navigation.canGoBack) return false;
          return true;
        })
        .map((btn) => {
          btn.functionCallback = this[btn.action];
          return btn;
        });
      return data;
    },
  },

  props: {
    type: {
      type: String,
      default: "404",
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style>
</style>