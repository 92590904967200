<template>
  <v-card
    elevation="0"
    class="rounded-xl d-flex pa-2 gap-4 align-center justify-space-between"
  >
    <!-- <v-img :src="selectedOrganization.cover" :aspect-ratio="21 / 9"></v-img> -->
    <div class="d-flex align-center gap-2">
      <base-avatar
        :src="selectedOrganization.photo"
        :seed="selectedOrganization.id"
        :size="50"
        avatarStyle="shapes"
        color="secondary lighten-2"
      />
      <div>
        <h6 class="mb-0">{{ selectedOrganization.name }}</h6>
      </div>
    </div>
    <div class="flex-shrink-1">
      <div class="gap-2 text-end">
        <v-btn
          v-if="hasPermission(256)"
          to="/admin/organization/profile"
          rounded
          small
          text
        >
          <v-icon small left> mdi-view-dashboard </v-icon>
          Perfil
        </v-btn>
        <v-btn
          v-if="hasPermission(8)"
          to="/admin/organization/members"
          rounded
          small
          text
        >
          <v-icon small left> mdi-account-group </v-icon>
          Membros
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import DescriptionWrap from "@/components/global/DescriptionWrap.vue";

export default {
  name: "OrganizationProfileCard",
  components: { DescriptionWrap },
  computed: {
    ...mapGetters("auth", ["hasPermission"]),
    ...mapGetters("organization", ["selectedOrganization"]),
  },
};
</script>

<style></style>
