<template>
  <v-alert
    v-model="show"
    :color="banner.color"
    :dismissible="banner.dismissible"
    class="mb-0 mx-2"
  >
    <component
      :is="bannerComponents[banner.template] || bannerComponents.default"
      :data="banner.data"
    />
  </v-alert>
</template>

<script>
import defaultBanner from "./bannerTemplate/default.vue";

export default {
  components: {
    defaultBanner,
  },
  data: () => ({
    show: true,
    bannerComponents: {
      default: defaultBanner,
    },
  }),
  watch: {
    show() {
      if (!this.show) {
        this.$emit("input");
        this.show = true;
      }
    },
  },
  props: {
    banner: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
