<template>
  <v-row justify="center">
    <v-col cols="12" md="10" lg="8" xl="6">
      <h2>Vamos criar uma organização?</h2>
      <!-- <p>
        Antes de começar, é importante que você entenda o que é uma organização.
      </p> -->

      <base-card class="pa-4">
        <v-form ref="form" v-model="valid" @submit.prevent="create">
          <v-text-field
            v-model="organization.name"
            :counter="50"
            :rules="rules.name"
            label="Nome da organização"
            required
            outlined
            :disabled="loading"
          ></v-text-field>

          <v-text-field
            v-model="organization.email"
            :rules="rules.email"
            label="E-mail"
            :disabled="loading"
            required
            outlined
          ></v-text-field>

          <phone-input
            :phone="organization.phone"
            @update:phone="(e) => (organization.phone = e)"
            :ddi="organization.ddi"
            @update:ddi="(e) => (organization.ddi = e)"
            @valid="(v) => (waValid = v)"
            dense
            outlined
            :disabled="loading"
          />

          <v-text-field-simplemask
            v-model="organization.document"
            :label="
              organization.document.length == 0
                ? 'CPF ou CNPJ'
                : organization.document.length < 15
                ? 'CPF'
                : 'CNPJ'
            "
            class="mb-3"
            v-bind:properties="{
              prefix: '',
              suffix: '',
              readonly: false,
              outlined: true,
              clearable: false,
              placeholder: '999.999.999-99',
              type: 'tel',
              rules: rules.document,
              disabled: loading,
            }"
            v-bind:options="{
              inputMask:
                organization.document.length < 15
                  ? '###.###.###-###'
                  : '##.###.###/####-##',
              outputMask:
                organization.document.length < 15
                  ? '###.###.###-###'
                  : '##.###.###/####-##',
              empty: '',
              applyAfter: false,
              alphanumeric: false,
              lowerCase: false,
            }"
          />

          <v-checkbox
            v-model="checkbox"
            :rules="[(v) => !!v || 'Você precisa aceitar os termos']"
            label="Concordo com os termos de uso e política de privacidade da organização"
            required
          ></v-checkbox>

          <v-alert
            v-if="error"
            border="left"
            class="text-left"
            text
            icon="mdi-alert-circle-outline"
            type="error"
          >
            {{ error }}
          </v-alert>

          <v-btn
            class="ma-2"
            color="primary"
            :disabled="!valid || !waValid"
            :loading="loading"
            type="submit"
          >
            Criar organização
          </v-btn>
        </v-form>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import validateCpf from "@/utils/validate-cpf";
import validateCnpj from "@/utils/validate-cnpj";
import ORGANIZATION from "@/services/admin/organization";
import PhoneInput from "../../../components/global/PhoneInput.vue";
export default {
  components: {
    PhoneInput,
  },
  data: () => ({
    loading: false,
    valid: false,
    waValid: false,
    error: false,
    organization: {
      name: "",
      email: "",
      ddi: 55,
      phone: "",
      document: "",
    },
    checkbox: false,
    rules: {
      name: [
        (v) => !!v || "Name é obrigatório",
        (v) => v.length < 50 || "Name deve ter menos de 50 caracteres",
      ],
      document: [
        (v) => !!v || "CPF ou CNPJ é obrigatório",
        (v) => validateCpf(v) || validateCnpj(v) || "CPF ou CNPJ inválido",
      ],
      email: [
        (v) => !!v || "Email é obrigatório",
        (v) =>
          /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
            v
          ) || "Email inválido",
      ],
    },
  }),
  methods: {
    ...mapActions("auth", ["renewToken"]),
    ...mapActions("organization", [
      "getAllOrganizations",
      "selectOrganization",
    ]),
    ...mapActions(["changeVerticalSidebarDrawer"]),

    async create() {
      try {
        this.error = false;
        this.loading = true;

        const response = await ORGANIZATION.create(this.organization);
        await this.renewToken();
        await this.getAllOrganizations();
        await this.changeVerticalSidebarDrawer(false);
        await this.selectOrganization(response.id);

        this.$confetti.start();
        await new Promise((r) => setTimeout(r, 1500));
        this.$confetti.stop();
        await new Promise((r) => setTimeout(r, 3500));
        this.$router.push({
          name: "admin.profile",
          params: { organizationId: response.id },
        });
      } catch (err) {
        this.error = err.message;
      } finally {
        this.loading = false;
        this.$confetti.stop();
      }
    },
    formatName(name) {
      return name
        .split(" ")
        .map((n) => (n.length > 3 ? n.charAt(0).toUpperCase() + n.slice(1) : n))
        .join(" ");
    },
  },
  watch: {
    "organization.name": {
      handler(val) {
        this.organization.name = this.formatName(val);
      },
      immediate: true,
    },
    "organization.email": {
      handler(val) {
        this.organization.email = val.toLowerCase();
      },
      immediate: true,
    },
  },
};
</script>

<style></style>
