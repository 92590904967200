import http from "../../http-common";

const stripe = async (orgId) => {
  return await http
    .get(`/admin/organization/${orgId}/stripe/settings`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const mercadopago = async (orgId, data) => {
  return await http
    .post(`/admin/organization/${orgId}/connector/mercadopago`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const mercadopagoGetLink = async (orgId) => {
  return await http
    .get(`/admin/organization/${orgId}/connector/mercadopago`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

export default {
  stripe,
  mercadopago,
  mercadopagoGetLink,
};
