var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"500px","overlay-opacity":"0.9"},on:{"click:outside":_vm.close},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Informações do Cliente ")]),_c('v-card-text',{staticClass:"pb-1 pt-4"},[_c('v-form',{ref:"transferForm",staticClass:"mb-4",on:{"submit":_vm.submit},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"label":"Nome","rules":_vm.nameRules,"dense":"","outlined":""},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}}),_c('v-text-field',{attrs:{"label":"Email","rules":_vm.emailRules,"dense":"","outlined":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('v-text-field-simplemask',{attrs:{"label":"CPF","properties":{
            prefix: '',
            suffix: '',
            outlined: true,
            placeholder: '999.999.999-99',
            type: 'tel',
            rules: _vm.cpfRules,
            dense: true,
            hideDetails: 'auto',
          },"options":{
            inputMask: '###.###.###-##',
            outputMask: '###.###.###-##',
            empty: '',
            applyAfter: false,
            alphanumeric: false,
            lowerCase: false,
          }},model:{value:(_vm.user.document),callback:function ($$v) {_vm.$set(_vm.user, "document", $$v)},expression:"user.document"}})],1)],1),_c('v-card-actions',{staticClass:"d-flex flex-column"},[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","block":"","disabled":!_vm.valid},on:{"click":_vm.submit}},[_vm._v(" Finalizar ")]),_c('v-btn',{staticClass:"ml-0",attrs:{"color":"primary","text":"","block":""},on:{"click":_vm.close}},[_vm._v(" Fechar ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }