var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"10","lg":"8","xl":"6"}},[_c('h2',[_vm._v("Vamos criar uma organização?")]),_c('base-card',{staticClass:"pa-4"},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.create.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"counter":50,"rules":_vm.rules.name,"label":"Nome da organização","required":"","outlined":"","disabled":_vm.loading},model:{value:(_vm.organization.name),callback:function ($$v) {_vm.$set(_vm.organization, "name", $$v)},expression:"organization.name"}}),_c('v-text-field',{attrs:{"rules":_vm.rules.email,"label":"E-mail","disabled":_vm.loading,"required":"","outlined":""},model:{value:(_vm.organization.email),callback:function ($$v) {_vm.$set(_vm.organization, "email", $$v)},expression:"organization.email"}}),_c('phone-input',{attrs:{"phone":_vm.organization.phone,"ddi":_vm.organization.ddi,"dense":"","outlined":"","disabled":_vm.loading},on:{"update:phone":(e) => (_vm.organization.phone = e),"update:ddi":(e) => (_vm.organization.ddi = e),"valid":(v) => (_vm.waValid = v)}}),_c('v-text-field-simplemask',{staticClass:"mb-3",attrs:{"label":_vm.organization.document.length == 0
              ? 'CPF ou CNPJ'
              : _vm.organization.document.length < 15
              ? 'CPF'
              : 'CNPJ',"properties":{
            prefix: '',
            suffix: '',
            readonly: false,
            outlined: true,
            clearable: false,
            placeholder: '999.999.999-99',
            type: 'tel',
            rules: _vm.rules.document,
            disabled: _vm.loading,
          },"options":{
            inputMask:
              _vm.organization.document.length < 15
                ? '###.###.###-###'
                : '##.###.###/####-##',
            outputMask:
              _vm.organization.document.length < 15
                ? '###.###.###-###'
                : '##.###.###/####-##',
            empty: '',
            applyAfter: false,
            alphanumeric: false,
            lowerCase: false,
          }},model:{value:(_vm.organization.document),callback:function ($$v) {_vm.$set(_vm.organization, "document", $$v)},expression:"organization.document"}}),_c('v-checkbox',{attrs:{"rules":[(v) => !!v || 'Você precisa aceitar os termos'],"label":"Concordo com os termos de uso e política de privacidade da organização","required":""},model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}}),(_vm.error)?_c('v-alert',{staticClass:"text-left",attrs:{"border":"left","text":"","icon":"mdi-alert-circle-outline","type":"error"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary","disabled":!_vm.valid || !_vm.waValid,"loading":_vm.loading,"type":"submit"}},[_vm._v(" Criar organização ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }