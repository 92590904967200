<template>
  <div class="pa-4">
    <div class="d-flex justify-space-between align-center mb-2">
      <h5 class="ma-0">Organizações com acesso</h5>
      <v-spacer />
      <v-btn small color="primary" @click="linkOrg">
        <v-icon small left>mdi-plus</v-icon>
        organização
      </v-btn>
    </div>

    <v-alert type="info" dense>
      <h6 class="mb-0">Organizações Parceiras</h6>
      <p class="mb-0">
        Conceder acesso permite que usuários da organização parceira interajam
        com sua conta, limitados pelas permissões que você definir.
      </p>
    </v-alert>

    <v-data-table
      :loading="loading"
      :items="organizations"
      :headers="headers"
      class="elevation-0"
    >
      <template v-slot:item.name="{ item }">
        <base-avatar
          :src="item.photo"
          :seed="item.id"
          :size="35"
          avatarStyle="shapes"
        />
        <span class="ml-2">{{ item.name }}</span>
      </template>
      <template v-slot:item.expiryDate="{ item }">
        <v-chip
          v-if="item.expiryDate"
          label
          small
          :color="new Date(item.expiryDate) < new Date() ? 'error' : 'success'"
        >
          {{ item.expiryDate | date("DD/MM/YYYY HH:mm") }}
        </v-chip>
        <v-chip v-else label small color="info"> Sem expiração </v-chip>
      </template>
      <template v-slot:item.permissions="{ item }">
        <v-menu open-on-hover top offset-y>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" class="text--disabled caption">
              <v-icon small> mdi-shield-lock </v-icon>
              {{ item.permissions.length }}
              {{ item.permissions.length != 1 ? "permissões" : "permissão" }}
            </span>
          </template>
          <vue-perfect-scrollbar
            :settings="{ suppressScrollX: true, wheelPropagation: false }"
            style="max-height: 430px"
          >
            <v-list dense>
              <v-list-item v-for="p in item.permissions" :key="p">
                <v-list-item-title>{{ permissions[p] }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </vue-perfect-scrollbar>
        </v-menu>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon v-if="!item.owner" small class="mr-2" @click="edit(item)">
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  components: { VuePerfectScrollbar },
  data() {
    return {
      page: 1,
      itemsPerPage: 10,
      search: "",
      headers: [
        {
          text: "Nome",
          value: "name",
        },
        {
          text: "Permissões",
          value: "permissions",
        },
        {
          text: "Válido até",
          value: "expiryDate",
        },
        {
          text: "Ações",
          value: "actions",
        },
      ],
    };
  },
  methods: {
    linkOrg() {
      this.$root.$emit("organization-link-modal");
    },
    edit(item) {
      this.$root.$emit("organization-link-modal", item);
    },
  },
  computed: {
    organizations() {
      return this.data?.organizations;
    },
  },
  props: {
    data: Object,
    loading: {
      type: Boolean,
      default: false,
    },
    permissions: Object,
  },
};
</script>

<style></style>
