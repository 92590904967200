<template>
  <div>
    <v-select
      v-model="template"
      :items="permissionsTemplates"
      label="Grupo de permissões"
      outlined
      dense
      item-text="label"
      item-value="id"
      @change="setPermissions"
      hide-details
      class="mb-2"
    />

    <v-card outlined v-if="template != 'custom'" class="pa-4 pb-2 rounded-lg">
      <b>Permissões</b>
      <div class="d-flex flex-wrap justify-center gap-1 mb-2">
        <v-chip
          v-for="p in value"
          :key="p"
          class="chip-permission"
          color="success"
          small
        >
          {{ permissions[p] }}
        </v-chip>
      </div>
      <v-btn small block text @click="customize"> Personalizar </v-btn>
    </v-card>

    <div v-else class="d-flex flex-column gap-2">
      <v-card
        :key="name"
        v-for="[name, perms] in Object.entries(permissionsGroups)"
        class="rounded-lg pa-3"
        elevation="0"
        outlined
      >
        <div class="d-flex align-center justify-space-between">
          <p class="text-subtitle-2 font-weight-black mb-0">{{ name }}</p>
          <v-btn small text @click="toggleAll(perms)">
            {{
              perms.every((p) => value.includes(`${p}`))
                ? "Desmarcar todos"
                : "Marcar todos"
            }}
          </v-btn>
        </div>
        <v-row dense class="mx-0">
          <v-col
            v-for="p in perms"
            :key="p"
            cols="12"
            sm="6"
            md="4"
            class="pt-0"
          >
            <v-checkbox
              class="mt-0"
              hide-details
              v-model="value"
              :label="permissions[p]"
              :value="`${p}`"
              :rules="[
                (v) => !!v || 'Permissão é obrigatória',
                (v) => !!v.length || 'Permissão é obrigatória',
              ]"
            />
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    template: null,
    forceCustom: false,
    permissionsTemplates: [
      { id: "seller", label: "Vendedor", permissions: ["32", "1024"] },
      { id: "financial", label: "Financeiro", permissions: ["2048", "4096"] },
      {
        id: "manager",
        label: "Gerente",
        permissions: ["32", "1024", "64", "128", "512"],
      },
      {
        id: "director",
        label: "Diretor",
        permissions: [
          "32",
          "1024",
          "64",
          "128",
          "512",
          "4194304",
          "8388608",
          "65536",
          "131072",
          "262144",
          "524288",
          "1048576",
          "2097152",
        ],
      },
      { id: "custom", label: "Personalizado", permissions: null },
    ],
  }),
  computed: {
    ...mapGetters("auth", ["permissions", "permissionsGroups"]),
    valuue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  methods: {
    customize() {
      this.forceCustom = true;
      this.template = "custom";
    },

    setPermissions() {
      if (this.template === "custom") return (this.forceCustom = true);
      this.forceCustom = false;
      this.valuue = this.permissionsTemplates.find(
        (t) => t.id === this.template
      ).permissions;
    },
    toggleAll(perms) {
      perms = perms.map((p) => `${p}`);
      const hasAll = perms.every((p) => this.value.includes(`${p}`));
      if (hasAll) {
        this.value = this.value.filter((p) => !perms.includes(p));
      } else {
        this.value = [
          ...this.value,
          ...perms.filter((p) => !this.value.includes(p)),
        ];
      }
    },
  },

  watch: {
    value(val) {
      if (this.forceCustom) return;
      this.template =
        this.permissionsTemplates.find(
          (t) =>
            t.permissions.length === val.length &&
            t.permissions.every((p) => val.includes(p))
        )?.id || "custom";
    },
  },

  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style></style>
