<template>
  <v-card elevation="0" class="pa-4 rounded-lg">
    <v-row class="align-center">
      <v-col cols="12" sm="4" md="2">
        <v-img
          src="@/assets/images/brands/mercadopago.svg"
          height="100"
          contain
        />
      </v-col>
      <v-col cols="12" sm="8" md="10">
        <h5>Conta Mercado Pago</h5>
        <v-alert
          border="left"
          text
          type="error"
          v-if="!selectedOrganization.mercadoPagoAccount"
        >
          <strong>Atenção!</strong>
          Sua conta não está pronta para receber pagamentos. Clique no botão
          abaixo para conectar
        </v-alert>
        <v-alert text outlined type="success" v-else>
          <strong>Parabéns!</strong>
          Sua conta está pronta para receber pagamentos.
        </v-alert>

        <!-- <v-btn
          text
          v-if="!editing && selectedOrganization.mercadoPagoAccount"
          @click="editing = true"
        >
          Mudar de conta
        </v-btn>
        <v-alert v-if="editing" type="info" border="left" text>
          <strong>Atenção!</strong>
          Você está prestes a mudar a conta de pagamento.
        </v-alert> -->
        <v-btn
          v-if="hasPermission(0) && (!selectedOrganization.mercadoPagoAccount || editing)"
          block
          @click="connect"
          color="primary"
          :loading="loading"
        >
          Conectar Conta
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import CONNECTOR from "@/services/admin/connector";

export default {
  data: () => ({
    loading: false,
    editing: false,
    error: false,
  }),
  methods: {
    async connect() {
      try {
        this.loading = true;
        this.error = false;
        const { url } = await CONNECTOR.mercadopagoGetLink(
          this.selectedOrganization.id
        );
        window.location.href = url;
      } catch (e) {
        this.error = e.message || "Erro ao salvar";
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["hasPermission"]),
    ...mapGetters("organization", ["selectedOrganization"]),
  },
};
</script>

<style></style>
