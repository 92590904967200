<template>
  <v-card class="rounded-lg" elevation="0">
    <div class="pa-3">
      <h6>
        <v-icon left small>mdi-cash-refund</v-icon>
        Solicitações de Reembolso
      </h6>
      <div class="d-flex flex-column gap-2">
        <div v-for="group in groupedByParty" :key="group.party.id">
          <v-card outlined class="pa-3 rounded-lg">
            <div class="d-flex gap-2 justify-space-between">
              <b>{{ group.party.name }}</b>
              <span class="flex-shrink-0">
                {{ group.refoundRequests.length }} pedido{{
                  group.refoundRequests.length == 1 ? "" : "s"
                }}
              </span>
            </div>
            <v-btn @click="openPartyRefunds(group.party.id)" text block x-small>
              Ver Solicitações
            </v-btn>
          </v-card>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  data: () => ({}),
  methods: {
    openPartyRefunds(partyId) {
      this.$router.push({
        name: "admin.party.management",
        params: { partyId },
        hash: "#refound",
      });
    },
  },
  computed: {
    groupedByParty() {
      const data = this.data.refoundRequests.reduce((acc, curr) => {
        const { Party } = curr.Ticket.TicketBlock.TicketGroup;
        if (!acc[Party.id]) {
          acc[Party.id] = {
            party: Party,
            refoundRequests: [],
          };
        }
        acc[Party.id].refoundRequests.push(curr);
        return acc;
      }, {});
      return Object.values(data);
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
