<template>
  <div>
    <v-hover v-slot="{ hover }">
      <v-img
        :src="selectedOrganization.cover || ''"
        class="rounded-xl"
        max-width="100%"
        :aspect-ratio="21 / 9"
      >
        <v-fade-transition leave-absolute>
          <v-card
            v-if="hover || !selectedOrganization.cover || fileLoading"
            @click="changeCover"
            class="d-flex transition-fast-in-fast-out grey darken-2 v-card--reveal text-h2 white--text justify-center align-center"
            style="height: 100%; opacity: 0.8"
          >
            <v-progress-circular indeterminate v-if="fileLoading" />
            <v-icon class="white--text" v-else>mdi-camera</v-icon>
          </v-card>
        </v-fade-transition>
      </v-img>
    </v-hover>
    <input
      type="file"
      accept="image/*"
      ref="coverInput"
      @change="onFileChange"
      v-show="false"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ORGANIZATION from "@/services/admin/organization";

export default {
  data: () => ({
    fileLoading: false,
    fileError: false,
  }),
  methods: {
    changeCover() {
      if (this.fileLoading) return;
      this.$refs.coverInput.click();
    },
    async onFileChange(e) {
      try {
        this.fileError = false;
        this.fileLoading = true;

        const file = e.target.files[0];
        let response = await ORGANIZATION.cover(
          this.selectedOrganization.id,
          file
        );
        this.$emit("refresh");
      } catch (error) {
        console.log(error);
        this.fileError = error.message;
        this.$refs.coverInput.value = "";
      } finally {
        this.fileLoading = false;
      }
    },
  },

  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
};
</script>

<style></style>
