<template>
  <v-data-table
    dense
    :headers="headers"
    :items="reducedByTicketBlock"
    :items-per-page="reducedByTicketBlock.length"
    hide-default-footer
    sort-by="createdAt"
  >
    <template v-slot:item.qty="{ item }">
      {{ item.qty }} venda{{ item.qty > 1 ? "s" : "" }}
    </template>
  </v-data-table>
</template>

<script>
export default {
  computed: {
    headers() {
      return [
        {
          text: "Lote",
          value: "name",
          sortable: false,
        },
        {
          text: "Quantidade",
          value: "qty",
          sortable: false,
        },
      ];
    },
    reducedByTicketBlock() {
      const reduce = this.sales.reduce((acc, sale) => {
        const ticketBlock = `${sale.ticketGroup}-${sale.ticketBlock}`;
        acc[ticketBlock] = acc[ticketBlock] || 0;
        acc[ticketBlock] += 1;
        return acc;
      }, {});

      return Object.entries(reduce).map(([name, qty]) => ({ name, qty }));
    },
  },
  props: {
    sales: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style></style>
