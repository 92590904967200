var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"700","scrollable":"","persistent":_vm.loading},on:{"click:outside":_vm.close},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.editing ? "Editar acesso da organização" : "Conceder acesso a organização")+" ")]),_c('v-card-text',{staticClass:"pa-4"},[(!_vm.editing)?_c('organization-select',{model:{value:(_vm.organization),callback:function ($$v) {_vm.organization=$$v},expression:"organization"}}):_c('v-card',{staticClass:"d-flex gap-2 align-center mb-4 pa-4 rounded-lg",attrs:{"outlined":""}},[_c('base-avatar',{attrs:{"src":_vm.organization?.photo,"seed":_vm.organization?.id || '',"size":50,"avatarStyle":"shapes"}}),_c('p',{staticClass:"text-subtitle-2 font-weight-black mb-0"},[_vm._v(" "+_vm._s(_vm.organization?.name)+" ")])],1),_c('v-text-field',{attrs:{"type":"datetime-local","label":"Data de expiração (opcional)","outlined":"","dense":"","min":new Date().toISOString().substr(0, 16),"rules":[
          (v) =>
            !v ||
            new Date(v) > new Date() ||
            'Data deve ser maior que a atual',
        ]},model:{value:(_vm.expiryDate),callback:function ($$v) {_vm.expiryDate=$$v},expression:"expiryDate"}}),_c('v-form',{on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('p',{staticClass:"text--primary font-weight-bold mb-1 ma-0"},[_vm._v("Permissões")]),_c('div',{staticClass:"d-flex flex-column gap-2"},_vm._l((Object.entries(_vm.permissionsGroups)),function([name, perms]){return _c('v-card',{key:name,staticClass:"rounded-lg pa-3",attrs:{"elevation":"0","outlined":""}},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('p',{staticClass:"text-subtitle-2 font-weight-black mb-0"},[_vm._v(_vm._s(name))]),_c('v-btn',{attrs:{"small":"","text":""},on:{"click":function($event){return _vm.toggleAll(perms)}}},[_vm._v(" "+_vm._s(perms.some((p) => !_vm.permission.includes(`${p}`)) ? "Marcar todos" : "Desmarcar todos")+" ")])],1),_c('v-row',{staticClass:"mx-0",attrs:{"dense":""}},_vm._l((perms),function(p){return _c('v-col',{key:p,staticClass:"pt-0",attrs:{"cols":"12","sm":"6","md":"4","align-self":"center"}},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"hide-details":"","label":_vm.permissions[p],"value":`${p}`,"rules":[
                    (v) => !!v || 'Permissão é obrigatória',
                    (v) => !!v.length || 'Permissão é obrigatória',
                  ]},model:{value:(_vm.permission),callback:function ($$v) {_vm.permission=$$v},expression:"permission"}})],1)}),1)],1)}),1)]),(!!_vm.error)?_c('v-alert',{attrs:{"outlined":"","text":"","color":"error"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()],1),_c('v-card-actions',[(_vm.editing)?_c('v-btn',{attrs:{"color":"danger","text":"","disabled":_vm.loading,"loading":_vm.loading},on:{"click":_vm.remove}},[_vm._v(" Remover ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":_vm.loading},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.loading || !_vm.valid || !_vm.organization?.id,"loading":_vm.loading},on:{"click":_vm.save}},[_vm._v(" Confirmar ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }