<template>
  <v-card class="rounded-lg" elevation="0">
    <v-img
      :aspect-ratio="16 / 9"
      :src="data.cover"
      :style="{
        position: 'relative',

        filter: !isActive ? `grayscale(80%)` : undefined,
      }"
    >
    </v-img>
    <v-chip
      style="position: absolute; top: 10px; left: 10px"
      :color="label.color"
      label
      small
    >
      {{ label.text }}
    </v-chip>
    <div class="pa-3">
      <!-- <h6 class="mb-0">{{ data.name }}</h6> -->
      <div class="d-flex align-center gap-2">
        <img
          v-if="data.rank <= 3"
          :src="`/img/rank/${data.rank}.png`"
          height="50px"
        />
        <h1 v-else class="mb-0">{{ data.rank }}º</h1>
        <div>
          <h6 class="mb-0">
            {{ data.count }} ingresso{{ data.count == 1 ? "" : "s" }} vendido{{
              data.count == 1 ? "" : "s"
            }}
          </h6>
          <small v-if="data.salesToNextRank">
            Venda mais {{ data.salesToNextRank }} ingresso{{
              data.salesToNextRank == 1 ? "" : "s"
            }}
            para subir de rank
          </small>
        </div>
      </div>
      <v-btn
        :disabled="!isActive"
        @click="sell"
        color="primary"
        class="mt-3"
        small
        block
      >
        {{ isActive ? "Vender" : "Evento encerrado" }}
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import moment from "moment";
export default {
  data: () => ({}),
  methods: {
    sell() {
      this.$router.push({
        name: "admin.sell",
        query: { id: this.data.id },
      });
    },
  },
  computed: {
    label() {
      if (!this.data?.endDate) return { text: "Sem data", color: "error" };
      if (moment().isBefore(this.data.date)) {
        // dias para o evento
        const days = moment(this.data.date).diff(moment(), "days");
        if (days == 0)
          return {
            text: "Hoje",
            color: "success",
          };
        return {
          text: `Em ${days} dia${days > 1 ? "s" : ""}`,
          color: "success",
        };
      } else if (moment().isBefore(this.data.endDate)) {
        return {
          text: "Em andamento",
          color: "warning",
        };
      } else {
        return {
          text: "Encerrado",
          color: "error",
        };
      }
    },

    isActive() {
      if (!this.data?.endDate) return false;
      return new Date(this.data.endDate) > new Date();
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
