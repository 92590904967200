<template>
  <v-card>
    <v-img :src="party.cover" :aspect-ratio="16 / 9" />
    <v-card-title class="flex-column justify-space-between align-start">
      <span class="text-overline" style="line-height: 1em">
        {{ totalSales }} venda{{ totalSales > 1 ? "s" : "" }}
      </span>
      <h5 class="mb-0">{{ party.name }}</h5>
    </v-card-title>
    <v-card-text>
      <v-alert
        :color="debit <= 0 ? 'success' : 'warning'"
        border="bottom"
        text
        dense
        v-if="!showDetails && debit != 0"
      >
        <template v-if="debit < 0">
          <div class="d-flex justify-space-between align-center">
            <h6 class="mb-0">Saldo</h6>
            <span> {{ debit | currency(true) }} </span>
          </div>
        </template>
        <template v-else>
          <div class="d-flex justify-space-between align-center">
            <h6 class="mb-0">Débito</h6>
            <span> {{ debit | currency(true) }} </span>
          </div>
        </template>
      </v-alert>

      <template v-if="showDetails">
        <v-divider />
        <div class="d-flex justify-space-between align-center mt-4">
          <h6 class="mb-0">Vendas</h6>
          <span>
            {{ party.online.length }} venda{{
              party.online.length > 1 ? "s" : ""
            }}
          </span>
        </div>
        <my-sales-table :sales="party.online" />

        <!-- Offline -->
        <template v-if="party.offline.length">
          <!-- Offline Sales -->
          <div class="d-flex justify-space-between align-center mt-4">
            <h6 class="mb-0">Vendas Externas</h6>
            <span>
              {{ party.offline.length }} venda{{
                party.offline.length > 1 ? "s" : ""
              }}
              -
              {{ offlineReduce | currency(true) }}
            </span>
          </div>
          <my-sales-table :sales="party.offline" />

          <!-- Recalls -->
          <div class="d-flex justify-space-between align-center mt-4">
            <h6 class="mb-0">Recolhimento</h6>
            <span> {{ recallReduce | currency(true) }} </span>
          </div>
          <my-offiline-recalls-table :sales="party.recalls" />

          <!-- Aler -->
          <v-alert
            :type="debit <= 0 ? 'success' : 'warning'"
            border="bottom"
            text
          >
            <template v-if="debit == 0">
              <h6 class="mb-0">Débito</h6>
              <span> Você não possui nenhum débito!</span>
            </template>
            <template v-else-if="debit < 0">
              <div class="d-flex justify-space-between align-center">
                <h6 class="mb-0">Saldo</h6>
                <span> {{ debit | currency(true) }} </span>
              </div>
            </template>
            <template v-else>
              <div class="d-flex justify-space-between align-center">
                <h6 class="mb-0">Débito a pagar</h6>
                <span> {{ debit | currency(true) }} </span>
              </div>
            </template>
          </v-alert>
        </template>
      </template>
      <div>
        <v-divider v-if="!showDetails" style="margin-bottom: -15px" />
        <v-btn small block text @click="showDetails = !showDetails">
          {{ showDetails ? "Ocultar" : "Mostrar" }} detalhes
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import MyOffilineRecallsTable from "./MyOffilineRecallsTable.vue";
import MySalesTable from "./MySalesTable.vue";
export default {
  data: () => ({
    showDetails: false,
  }),
  components: { MySalesTable, MyOffilineRecallsTable },
  computed: {
    totalSales() {
      return this.party.online.length + this.party.offline.length;
    },
    offlineReduce() {
      return this.party.offline.reduce((acc, curr) => {
        return acc + curr.amount;
      }, 0);
    },
    recallReduce() {
      return this.party.recalls.reduce((acc, curr) => {
        return acc + curr.amount;
      }, 0);
    },
    debit() {
      return this.offlineReduce - this.recallReduce;
    },
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
