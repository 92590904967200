<template>
  <div>
    <v-hover v-slot="{ hover }">
      <v-avatar size="100" color="grey">
        <v-img :src="selectedOrganization.photo">
          <v-fade-transition leave-absolute>
            <v-avatar
              v-if="hover || !selectedOrganization.photo || photoLoading"
              @click="changePhoto"
              class="d-flex transition-fast-in-fast-out grey v-card--reveal text-h2 white--text justify-center align-center"
              style="opacity: 0.8"
              size="100"
            >
              <v-progress-circular indeterminate v-if="photoLoading" />
              <v-icon class="white--text" v-else>mdi-camera</v-icon>
            </v-avatar>
          </v-fade-transition>
        </v-img>
      </v-avatar>
    </v-hover>
    <input
      type="file"
      accept="image/*"
      ref="photoInput"
      @change="onPhotoChange"
      v-show="false"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ORGANIZATION from "@/services/admin/organization";

export default {
  data: () => ({
    photoLoading: false,
    photoError: false,
  }),
  methods: {
    changePhoto() {
      if (this.photoLoading) return;
      this.$refs.photoInput.click();
    },
    async onPhotoChange(e) {
      try {
        this.photoError = false;
        this.photoLoading = true;

        const file = e.target.files[0];
        let response = await ORGANIZATION.photo(
          this.selectedOrganization.id,
          file
        );
        this.$emit("refresh");
      } catch (error) {
        console.log(error);
        this.photoError = error.message;
        this.$refs.photoInput.value = "";
      } finally {
        this.photoLoading = false;
      }
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
};
</script>

<style></style>
