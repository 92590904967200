<template>
  <div class="d-flex flex-wrap">
    <v-icon v-if="data.icon">{{ data.icon }}</v-icon>
    <div>
      <div v-if="data.title" class="font-weight-bold">{{ data.title }}</div>
      <div v-if="data.description">{{ data.description }}</div>
    </div>
    <v-btn v-if="data.button" :to="data.button.to" text small>
      {{ data.button.text }}
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
