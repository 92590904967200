<template>
  <v-card elevation="0" class="rounded-lg pa-4">
    <h6>Descrição</h6>
    <base-editor v-model="description" />
    <v-alert v-if="error" type="error" class="mt-2" border="left" text>
      {{ error }}
    </v-alert>
    <v-btn
      color="success"
      small
      block
      class="mt-2"
      @click="save"
      :loading="loading"
      :disabled="!hasChanges"
    >
      Salvar
    </v-btn>
  </v-card>
</template>

<script>
import ORGANIZATION from "@/services/admin/organization";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      loading: false,
      error: false,
      description: "",
    };
  },
  methods: {
    async save() {
      try {
        this.loading = true;
        this.error = false;
        await ORGANIZATION.update(this.selectedOrganization.id, {
          description: this.description,
        });
        this.$emit("refresh");
      } catch (e) {
        this.error = e.message || "Erro ao salvar a descrição";
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    "selectedOrganization.description"(value) {
      this.description = value;
    },
  },
  mounted() {
    this.description = this.selectedOrganization.description;
  },

  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    hasChanges() {
      return this.description !== this.selectedOrganization.description;
    },
  },
};
</script>

<style></style>
