<template>
  <div class="pa-4">
    <div class="d-flex justify-space-between align-center mb-2">
      <h5 class="ma-0">Membros</h5>
      <v-btn small color="primary" @click="newMember">
        <v-icon small left>mdi-plus</v-icon>
        membro
      </v-btn>
    </div>
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Pesquisar"
      single-line
      dense
      hide-details
      outlined
    />

    <v-data-table
      :loading="loading"
      :items="members"
      :headers="headers"
      :search="search"
      class="elevation-0"
    >
      <template v-slot:item.name="{ item }">
        <base-avatar
          v-if="!!item.name"
          :src="item.photo"
          :seed="item.id"
          :size="35"
        />
        <v-avatar v-else size="35">
          <v-icon large>mdi-account-question</v-icon>
        </v-avatar>
        <span class="ml-2">{{ item.name || "Conta não criada" }}</span>
      </template>
      <template v-slot:item.permissions="{ item }">
        <template v-if="item.owner">
          <v-icon small>mdi-crown</v-icon>
          <span class="text--disabled caption"> Dono </span>
        </template>
        <v-menu v-else open-on-hover top offset-y>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" class="text--disabled caption">
              <v-icon small> mdi-shield-lock </v-icon>
              {{ item.permissions.length }}
              {{ item.permissions.length != 1 ? "permissões" : "permissão" }}
            </span>
          </template>
          <vue-perfect-scrollbar
            :settings="{ suppressScrollX: true, wheelPropagation: false }"
            style="max-height: 430px"
          >
            <v-list dense>
              <v-list-item v-for="p in item.permissions" :key="p">
                <v-list-item-title>{{ permissions[p] }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </vue-perfect-scrollbar>
        </v-menu>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon v-if="!item.owner" small class="mr-2" @click="edit(item)">
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  components: { VuePerfectScrollbar },
  data() {
    return {
      page: 1,
      itemsPerPage: 10,
      search: "",
      headers: [
        {
          text: "Nome",
          value: "name",
        },
        {
          text: "Telefone",
          value: "phone",
        },
        {
          text: "Permissões",
          value: "permissions",
        },
        {
          text: "Ações",
          value: "actions",
        },
      ],
    };
  },
  methods: {
    newMember() {
      this.$root.$emit("organization-member-modal");
    },
    edit(user) {
      this.$root.$emit("organization-member-modal", user);
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
  },
  computed: {
    members() {
      return this.data?.members || [];
    },
  },
  props: {
    data: Object,
    loading: {
      type: Boolean,
      default: false,
    },
    permissions: Object,
  },
};
</script>

<style></style>
