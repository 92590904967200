<template>
  <v-dialog
    v-model="isOpen"
    width="700"
    scrollable
    :persistent="loading"
    @click:outside="close"
  >
    <v-card>
      <v-card-title class="text-h6">
        {{ member.id ? "Editar membro" : "Adicionar membro" }}
      </v-card-title>

      <v-card-text class="pa-4 pt-0">
        <phone-input
          v-if="!member.id"
          :phone="member.phone"
          @update:phone="(e) => (member.phone = e)"
          :ddi="member.ddi"
          @update:ddi="(e) => (member.ddi = e)"
          @valid="(v) => (valid = v)"
          dense
          outlined
          :disabled="loading || !!member.id"
        />
        <v-card
          outlined
          v-else
          class="d-flex gap-2 align-center mb-3 pa-4 rounded-lg"
        >
          <base-avatar
            v-if="!!member.name"
            :src="member.photo"
            :seed="member.id"
            :size="50"
          />
          <v-avatar v-else size="50">
            <v-icon large>mdi-account-question</v-icon>
          </v-avatar>
          <div>
            <h6 class="mb-0">{{ member.name || "Conta não criada" }}</h6>
            <span>{{ member.phone }}</span>
          </div>
        </v-card>
        <v-form @submit.prevent v-model="valid">
          <p class="text--primary font-weight-bold mb-1 ma-0">Permissões</p>
          <!-- <permissions-helper v-model="member.permissions" /> -->

          <div class="d-flex flex-column gap-2">
            <v-card
              :key="name"
              v-for="[name, perms] in Object.entries(permissionsGroups)"
              class="rounded-lg pa-3"
              elevation="0"
              outlined
            >
              <div class="d-flex align-center justify-space-between">
                <p class="text-subtitle-2 font-weight-black mb-0">{{ name }}</p>
                <v-btn small text @click.stop="toggleAll(perms)">
                  {{
                    perms.some(
                      (p) => !(member?.permissions || []).includes(`${p}`)
                    )
                      ? "Marcar todos"
                      : "Desmarcar todos"
                  }}
                </v-btn>
              </div>
              <v-row dense class="mx-0">
                <v-col
                  v-for="p in perms"
                  :key="p"
                  cols="12"
                  sm="6"
                  md="4"
                  class="pt-0"
                >
                  <v-checkbox
                    class="mt-0"
                    hide-details
                    v-model="member.permissions"
                    :label="permissions[p]"
                    :value="`${p}`"
                    :rules="[
                      (v) => !!v || 'Permissão é obrigatória',
                      (v) => !!v.length || 'Permissão é obrigatória',
                    ]"
                  />
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-form>
        <v-alert v-if="!!error" outlined text color="error">
          {{ error }}
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-btn
          v-if="!!member.id"
          color="danger"
          text
          :disabled="loading"
          :loading="loading"
          @click="remove"
        >
          Remover
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text :disabled="loading" @click="close"> Cancelar </v-btn>
        <v-btn
          color="primary"
          :disabled="loading || !valid"
          :loading="loading"
          @click="save"
        >
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import ORGANIZATION from "@/services/admin/organization";
import PhoneInput from "../../global/PhoneInput.vue";
import PermissionsHelper from "../../global/PermissionsHelper.vue";

const defaultMember = () => ({
  id: null,
  phone: "",
  ddi: 55,
  permissions: [],
});
export default {
  components: { PhoneInput, PermissionsHelper },
  data: () => ({
    isOpen: false,
    loading: false,
    valid: false,
    error: false,
    member: defaultMember(),
  }),

  methods: {
    open(data = {}) {
      this.isOpen = true;
      this.member = Object.assign(defaultMember(), data);
      this.$nextTick(() => {
        if (this.$refs.form) this.$refs.form.resetValidation();
      });
    },
    close() {
      if (this.loading) return;
      this.isOpen = false;
      this.loading = false;
      this.error = false;
      this.member = defaultMember();
    },
    toggleAll(perms) {
      const memberPermissions = this.member?.permissions || [];
      perms = perms.map((p) => `${p}`);
      const hasAll = perms.every((p) => memberPermissions.includes(p));
      if (hasAll) {
        this.member.permissions = memberPermissions.filter(
          (p) => !perms.includes(p)
        );
      } else {
        this.member.permissions = [
          ...memberPermissions,
          ...perms.filter((p) => !memberPermissions.includes(p)),
        ];
      }
    },

    reducePermissions(permissions) {
      return permissions.reduce((acc, permission) => {
        return acc + parseInt(permission);
      }, 0);
    },
    async remove() {
      try {
        if (!window.confirm("Tem certeza que deseja remover este membro?"))
          return;
        this.loading = true;
        this.error = false;
        let orgId = this.selectedOrganization.id;
        const response = await ORGANIZATION.removeMember(orgId, this.member.id);
        this.$emit("success", response);
        this.loading = false;
        this.close();
      } catch (e) {
        this.error = e.message;
        this.loading = false;
      }
    },
    async save() {
      try {
        this.loading = true;
        this.error = false;
        let orgId = this.selectedOrganization.id;
        const response = await ORGANIZATION.updateMember(orgId, {
          phone: this.member.phone,
          ddi: this.member.ddi,
          permission: this.reducePermissions(this.member.permissions),
        });
        this.$emit("success", response);
        this.loading = false;
        this.close();
      } catch (e) {
        this.error = e.message;
        this.loading = false;
      }
    },
  },

  computed: {
    ...mapGetters("auth", ["permissions", "permissionsGroups"]),
    ...mapGetters("organization", ["selectedOrganization"]),
    permissionsArray() {
      return Object.entries(this.permissions).map(([key, value]) => ({
        value: key,
        text: value,
      }));
    },
  },
  mounted() {
    this.$root.$on("organization-member-modal", this.open);
  },
};
</script>

<style></style>
