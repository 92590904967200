<template>
  <v-dialog
    v-model="isOpen"
    width="700"
    scrollable
    :persistent="loading"
    @click:outside="close"
  >
    <v-card>
      <v-card-title class="text-h6">
        {{
          editing
            ? "Editar acesso da organização"
            : "Conceder acesso a organização"
        }}
      </v-card-title>

      <v-card-text class="pa-4">
        <organization-select v-if="!editing" v-model="organization" />
        <v-card
          outlined
          v-else
          class="d-flex gap-2 align-center mb-4 pa-4 rounded-lg"
        >
          <base-avatar
            :src="organization?.photo"
            :seed="organization?.id || ''"
            :size="50"
            avatarStyle="shapes"
          />
          <p class="text-subtitle-2 font-weight-black mb-0">
            {{ organization?.name }}
          </p>
        </v-card>
        <v-text-field
          v-model="expiryDate"
          type="datetime-local"
          label="Data de expiração (opcional)"
          outlined
          dense
          :min="new Date().toISOString().substr(0, 16)"
          :rules="[
            (v) =>
              !v ||
              new Date(v) > new Date() ||
              'Data deve ser maior que a atual',
          ]"
        />
        <v-form @submit.prevent v-model="valid">
          <p class="text--primary font-weight-bold mb-1 ma-0">Permissões</p>
          <div class="d-flex flex-column gap-2">
            <v-card
              :key="name"
              v-for="[name, perms] in Object.entries(permissionsGroups)"
              class="rounded-lg pa-3"
              elevation="0"
              outlined
            >
              <div class="d-flex align-center justify-space-between">
                <p class="text-subtitle-2 font-weight-black mb-0">{{ name }}</p>
                <v-btn small text @click="toggleAll(perms)">
                  {{
                    perms.some((p) => !permission.includes(`${p}`))
                      ? "Marcar todos"
                      : "Desmarcar todos"
                  }}
                </v-btn>
              </div>
              <v-row dense class="mx-0">
                <v-col
                  v-for="p in perms"
                  :key="p"
                  cols="12"
                  sm="6"
                  md="4"
                  class="pt-0"
                  align-self="center"
                >
                  <v-checkbox
                    class="mt-0"
                    hide-details
                    v-model="permission"
                    :label="permissions[p]"
                    :value="`${p}`"
                    :rules="[
                      (v) => !!v || 'Permissão é obrigatória',
                      (v) => !!v.length || 'Permissão é obrigatória',
                    ]"
                  />
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-form>
        <v-alert v-if="!!error" outlined text color="error">
          {{ error }}
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-btn
          v-if="editing"
          color="danger"
          text
          :disabled="loading"
          :loading="loading"
          @click="remove"
        >
          Remover
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text :disabled="loading" @click="close"> Cancelar </v-btn>
        <v-btn
          color="primary"
          :disabled="loading || !valid || !organization?.id"
          :loading="loading"
          @click="save"
        >
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import ORGANIZATION from "@/services/admin/organization";
import OrganizationSelect from "./modules/OrganizationSelect.vue";
import moment from "moment";
export default {
  components: { OrganizationSelect },
  data: () => ({
    isOpen: false,
    loading: false,
    valid: false,
    error: false,
    organization: null,
    expiryDate: null,
    editing: false,
    permission: [],
  }),

  methods: {
    open({ permissions = [], expiryDate, linkId, ...organization } = {}) {
      this.isOpen = true;

      this.editing = !!linkId;
      this.organization = Object.assign({}, organization || {});
      this.expiryDate = expiryDate
        ? moment(expiryDate).format("YYYY-MM-DDTHH:mm")
        : null;
      this.permission = permissions || [];
    },
    close() {
      if (this.loading) return;
      this.isOpen = false;
      this.loading = false;
      this.error = false;
      this.organization = null;
      this.expiryDate = null;
      this.permission = [];
    },
    toggleAll(perms) {
      perms = perms.map((p) => `${p}`);
      const hasAll = perms.every((p) => this.permission.includes(p));
      if (hasAll) {
        this.permission = this.permission.filter((p) => !perms.includes(p));
      } else {
        this.permission = [
          ...this.permission,
          ...perms.filter((p) => !this.permission.includes(p)),
        ];
      }
    },
    reducePermissions(permissions) {
      return permissions.reduce((acc, permission) => {
        return acc + parseInt(permission);
      }, 0);
    },
    async remove() {
      try {
        if (
          !window.confirm(
            "Tem certeza que deseja remover o acesso dessa organização?"
          )
        )
          return;
        this.loading = true;
        this.error = false;
        let orgId = this.selectedOrganization.id;
        const response = await ORGANIZATION.grantAccess.remove(
          orgId,
          this.organization.id
        );
        this.$emit("success", response);
        this.loading = false;
        this.close();
      } catch (e) {
        this.error = e.message;
        this.loading = false;
      }
    },
    async save() {
      try {
        this.loading = true;
        this.error = false;
        let orgId = this.selectedOrganization.id;
        const response = await ORGANIZATION.grantAccess.update(orgId, {
          organizationId: this.organization.id,
          expiryDate: this.expiryDate
            ? new Date(this.expiryDate).toISOString()
            : null,
          permission: this.reducePermissions(this.permission),
        });
        this.$emit("success", response);
        this.loading = false;
        this.close();
      } catch (e) {
        this.error = e.message;
        this.loading = false;
      }
    },
  },

  computed: {
    ...mapGetters("auth", ["permissions", "permissionsGroups"]),
    ...mapGetters("organization", ["selectedOrganization"]),
    permissionsArray() {
      return Object.entries(this.permissions).map(([key, value]) => ({
        value: key,
        text: value,
      }));
    },
  },
  mounted() {
    this.$root.$on("organization-link-modal", this.open);
  },
};
</script>

<style></style>
