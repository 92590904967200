import http from "@/http-common";

const create = async (data) => {
  return await http
    .post("/admin/organization", data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const orgHome = async (organizationId) => {
  return await http
    .get(`/admin/organization/${organizationId}/ui/home`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

export default {
  organization: {
    home: orgHome,
  },
};
