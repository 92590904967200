<template>
  <div class="d-flex flex-column gap-3">
    <!-- Header -->
    <organization-profile />

    <home-banners :banners="banners" />

    <!-- Content -->
    <cols-organizer :items="uiData" cols="1" sm="2" md="2" lg="3">
      <template v-slot="{ item }">
        <component :is="item.name" :data="item.data" />
      </template>
    </cols-organizer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UI_SERVICE from "@/services/admin/ui";

import OrganizationProfile from "@/components/admin/organization/home/OrganizationProfile.vue";
import NextParty from "@/components/admin/organization/home/NextParty.vue";
import TicketSold from "@/components/admin/organization/home/TicketSold.vue";
import PromoterRanking from "@/components/admin/organization/home/PromoterRanking.vue";
import RefoundRequest from "@/components/admin/organization/home/RefoundRequest.vue";
import ColsOrganizer from "../../../components/global/ColsOrganizer.vue";
import HomeBanners from "../../../components/admin/organization/home/HomeBanners.vue";

export default {
  components: {
    OrganizationProfile,
    NextParty,
    TicketSold,
    PromoterRanking,
    RefoundRequest,
    ColsOrganizer,
    HomeBanners,
  },
  name: "Home",
  data: () => ({
    loading: false,
    uiData: [],
    banners: [],
  }),

  methods: {
    async getHomeData() {
      try {
        this.loading = true;
        const { results, banners } = await UI_SERVICE.organization.home(
          this.selectedOrganization.id
        );
        this.uiData = results;
        this.banners = banners;
      } catch (e) {
        this.error = e.message || "Ocorrera um erro ao carregar os dados";
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    "selectedOrganization.id"() {
      this.uiData = [];
      this.banners = [];
      this.getHomeData();
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  mounted() {
    this.getHomeData();
  },
};
</script>

<style></style>
